import React from "react";
import type { NextPage } from "next";
import { useEffect } from "react";
import { Text, Flex } from "@chakra-ui/react";
import strings from "~/resources/strings";

const NotFound: NextPage = () => {
	const pageStrings = strings.screen.notFound;

	useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			setTimeout(() =>
				location.href = "/",
			3000,
			);
		}
	}, []);

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			height="40vh"
			flexDir="column"
		>
			<Text
				fontSize={{ base:"3xl",sm: "4xl", md:"5xl"}}
				color="primary.500"
				fontWeight="bold"
				textAlign="center"
			>
				{pageStrings.title}
			</Text>
			<Text
				fontSize={{ base:"md", sm:"xl", md:"2xl" }}
				color="secondary.500"
				textAlign="center"
				p={2}
			>
				{pageStrings.helper}
			</Text>
		</Flex>
	);
};

export default NotFound;
